export const tailwindConfig = {
  theme: {
    colors: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    containers: {
      'xs': '320px',
      'sm': '384px',
      'md': '448px',
      'lg': '512px',
      'xl': '576px',
      '2xl': '672px',
      '3xl': '768px',
      '4xl': '896px',
      '5xl': '1024px',
      '6xl': '1152px',
      '7xl': '1280px',
    },
    fontFamily: {
      email: 'Arial',
      body: 'var(--font-body)',
      heading: 'var(--font-heading)',
    },
    screens: {
      'sm': '640px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1280px',
      '2xl': '1536px',
    },
    typography: {
      hero: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        '@apply text-pretty': {},
        'font-size': '6rem',
        'line-height': '6rem',
      },
      h1: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        '@apply text-pretty': {},
        'font-size': '4rem',
        'line-height': '4rem',
      },
      h2: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        '@apply text-pretty': {},
        'font-size': '3rem',
        'line-height': '3.5rem',
      },
      h3: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        '@apply text-pretty': {},
        'font-size': '2.5rem',
        'line-height': '3rem',
      },
      h4: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        '@apply text-pretty': {},
        'font-size': '2rem',
        'line-height': '2.25rem',
      },
      h5: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        '@apply text-pretty': {},
        'font-size': '1.5rem',
        'line-height': '1.75rem',
      },
      h6: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        '@apply text-pretty': {},
        'font-size': '1.25rem',
        'line-height': '1.75rem',
      },
      subtitle1: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        '@apply text-pretty': {},
        'font-size': '1.125rem',
        'line-height': '1.5rem',
      },
      subtitle2: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        '@apply text-pretty': {},
        'font-size': '1rem',
        'line-height': '1.375rem',
      },
      body1: {
        '@apply font-body': {},
        '@apply text-pretty': {},
        'font-size': '1.25rem',
        'line-height': '2rem',
      },
      body2: {
        '@apply font-body': {},
        '@apply text-pretty': {},
        'font-size': '1.125rem',
        'line-height': '1.75rem',
      },
      body3: {
        '@apply font-body': {},
        '@apply text-pretty': {},
        'font-size': '1rem',
        'line-height': '1.5rem',
      },
      button1: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        'font-size': '1rem',
        'line-height': '1rem',
      },
      button2: {
        '@apply font-bold': {},
        '@apply font-heading': {},
        'font-size': '0.875rem',
        'line-height': '1rem',
      },
      caption1: {
        '@apply font-body': {},
        '@apply text-pretty': {},
        'font-size': '0.875rem',
        'line-height': '1.125rem',
      },
      caption2: {
        '@apply font-body': {},
        '@apply text-pretty': {},
        'font-size': '0.75rem',
        'line-height': '1rem',
      },
    },
    accentColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
      auto: 'auto',
    },
    animation: {
      'none': 'none',
      'spin': 'spin 1s linear infinite',
      'ping': 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
      'pulse': 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      'bounce': 'bounce 1s infinite',
      'accordion-down': 'accordion-down 0.2s ease-out',
      'accordion-up': 'accordion-up 0.2s ease-out',
      'circular-progress': 'circular-progress-rotate 1.4s linear infinite',
      'circular-progress-dash':
        'circular-progress-dash 1.4s ease-in-out infinite',
      'linear-progress-bar1':
        'linear-progress-indeterminate-bar1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite',
      'linear-progress-bar2':
        'linear-progress-indeterminate-bar2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite',
      'wave': 'wave 2s linear 0.5s infinite',
    },
    aria: {
      busy: 'busy="true"',
      checked: 'checked="true"',
      disabled: 'disabled="true"',
      expanded: 'expanded="true"',
      hidden: 'hidden="true"',
      pressed: 'pressed="true"',
      readonly: 'readonly="true"',
      required: 'required="true"',
      selected: 'selected="true"',
    },
    aspectRatio: {
      auto: 'auto',
      square: '1 / 1',
      video: '16 / 9',
      paper: '8.5 / 11',
    },
    backdropBlur: {
      '0': '0',
      'none': '',
      'sm': '4px',
      'DEFAULT': '8px',
      'md': '12px',
      'lg': '16px',
      'xl': '24px',
      '2xl': '40px',
      '3xl': '64px',
    },
    backdropBrightness: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '90': '.9',
      '95': '.95',
      '100': '1',
      '105': '1.05',
      '110': '1.1',
      '125': '1.25',
      '150': '1.5',
      '200': '2',
    },
    backdropContrast: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '100': '1',
      '125': '1.25',
      '150': '1.5',
      '200': '2',
    },
    backdropGrayscale: { '0': '0', 'DEFAULT': '100%' },
    backdropHueRotate: {
      '0': '0deg',
      '15': '15deg',
      '30': '30deg',
      '60': '60deg',
      '90': '90deg',
      '180': '180deg',
    },
    backdropInvert: { '0': '0', 'DEFAULT': '100%' },
    backdropOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '15': '0.15',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '35': '0.35',
      '40': '0.4',
      '45': '0.45',
      '50': '0.5',
      '55': '0.55',
      '60': '0.6',
      '65': '0.65',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '85': '0.85',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
    },
    backdropSaturate: {
      '0': '0',
      '50': '.5',
      '100': '1',
      '150': '1.5',
      '200': '2',
    },
    backdropSepia: { '0': '0', 'DEFAULT': '100%' },
    backgroundColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    backgroundImage: {
      'none': 'none',
      'gradient-to-t': 'linear-gradient(to top, var(--tw-gradient-stops))',
      'gradient-to-tr':
        'linear-gradient(to top right, var(--tw-gradient-stops))',
      'gradient-to-r': 'linear-gradient(to right, var(--tw-gradient-stops))',
      'gradient-to-br':
        'linear-gradient(to bottom right, var(--tw-gradient-stops))',
      'gradient-to-b': 'linear-gradient(to bottom, var(--tw-gradient-stops))',
      'gradient-to-bl':
        'linear-gradient(to bottom left, var(--tw-gradient-stops))',
      'gradient-to-l': 'linear-gradient(to left, var(--tw-gradient-stops))',
      'gradient-to-tl':
        'linear-gradient(to top left, var(--tw-gradient-stops))',
      'gradient-circle': 'radial-gradient(circle, var(--tw-gradient-stops))',
    },
    backgroundOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '15': '0.15',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '35': '0.35',
      '40': '0.4',
      '45': '0.45',
      '50': '0.5',
      '55': '0.55',
      '60': '0.6',
      '65': '0.65',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '85': '0.85',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
    },
    backgroundPosition: {
      'bottom': 'bottom',
      'center': 'center',
      'left': 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      'right': 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      'top': 'top',
    },
    backgroundSize: { auto: 'auto', cover: 'cover', contain: 'contain' },
    blur: {
      '0': '0',
      'none': '',
      'sm': '4px',
      'DEFAULT': '8px',
      'md': '12px',
      'lg': '16px',
      'xl': '24px',
      '2xl': '40px',
      '3xl': '64px',
    },
    borderColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
      DEFAULT: 'currentColor',
    },
    borderOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '15': '0.15',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '35': '0.35',
      '40': '0.4',
      '45': '0.45',
      '50': '0.5',
      '55': '0.55',
      '60': '0.6',
      '65': '0.65',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '85': '0.85',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
    },
    borderRadius: {
      'none': '0px',
      'sm': '0.125rem',
      'DEFAULT': '0.25rem',
      'md': '0.375rem',
      'lg': '0.5rem',
      'xl': '0.75rem',
      '2xl': '1rem',
      '3xl': '1.5rem',
      'full': '9999px',
    },
    borderSpacing: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
    },
    borderWidth: {
      '0': '0px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      'DEFAULT': '1px',
    },
    boxShadow: {
      'sm': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      'DEFAULT':
        '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      'md': '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      'lg': '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      'xl': '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
      '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
      'inner': 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
      'none': 'none',
    },
    boxShadowColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    brightness: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '90': '.9',
      '95': '.95',
      '100': '1',
      '105': '1.05',
      '110': '1.1',
      '125': '1.25',
      '150': '1.5',
      '200': '2',
    },
    caretColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    columns: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      'auto': 'auto',
      '3xs': '16rem',
      '2xs': '18rem',
      'xs': '20rem',
      'sm': '24rem',
      'md': '28rem',
      'lg': '32rem',
      'xl': '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem',
      '7xl': '80rem',
    },
    container: {},
    content: { none: 'none' },
    contrast: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '100': '1',
      '125': '1.25',
      '150': '1.5',
      '200': '2',
    },
    cursor: {
      'auto': 'auto',
      'default': 'default',
      'pointer': 'pointer',
      'wait': 'wait',
      'text': 'text',
      'move': 'move',
      'help': 'help',
      'not-allowed': 'not-allowed',
      'none': 'none',
      'context-menu': 'context-menu',
      'progress': 'progress',
      'cell': 'cell',
      'crosshair': 'crosshair',
      'vertical-text': 'vertical-text',
      'alias': 'alias',
      'copy': 'copy',
      'no-drop': 'no-drop',
      'grab': 'grab',
      'grabbing': 'grabbing',
      'all-scroll': 'all-scroll',
      'col-resize': 'col-resize',
      'row-resize': 'row-resize',
      'n-resize': 'n-resize',
      'e-resize': 'e-resize',
      's-resize': 's-resize',
      'w-resize': 'w-resize',
      'ne-resize': 'ne-resize',
      'nw-resize': 'nw-resize',
      'se-resize': 'se-resize',
      'sw-resize': 'sw-resize',
      'ew-resize': 'ew-resize',
      'ns-resize': 'ns-resize',
      'nesw-resize': 'nesw-resize',
      'nwse-resize': 'nwse-resize',
      'zoom-in': 'zoom-in',
      'zoom-out': 'zoom-out',
    },
    divideColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
      DEFAULT: 'currentColor',
    },
    divideOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '15': '0.15',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '35': '0.35',
      '40': '0.4',
      '45': '0.45',
      '50': '0.5',
      '55': '0.55',
      '60': '0.6',
      '65': '0.65',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '85': '0.85',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
    },
    divideWidth: {
      '0': '0px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      'DEFAULT': '1px',
    },
    dropShadow: {
      'sm': '0 1px 1px rgb(0 0 0 / 0.05)',
      'DEFAULT': ['0 1px 2px rgb(0 0 0 / 0.1)', '0 1px 1px rgb(0 0 0 / 0.06)'],
      'md': ['0 4px 3px rgb(0 0 0 / 0.07)', '0 2px 2px rgb(0 0 0 / 0.06)'],
      'lg': ['0 10px 8px rgb(0 0 0 / 0.04)', '0 4px 3px rgb(0 0 0 / 0.1)'],
      'xl': ['0 20px 13px rgb(0 0 0 / 0.03)', '0 8px 5px rgb(0 0 0 / 0.08)'],
      '2xl': '0 25px 25px rgb(0 0 0 / 0.15)',
      'none': '0 0 #0000',
    },
    fill: {
      none: 'none',
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    flex: {
      '1': '1 1 0%',
      'auto': '1 1 auto',
      'initial': '0 1 auto',
      'none': 'none',
    },
    flexBasis: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'auto': 'auto',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      'full': '100%',
    },
    flexGrow: { '0': '0', 'DEFAULT': '1' },
    flexShrink: { '0': '0', 'DEFAULT': '1' },
    fontSize: {
      'xs': ['0.75rem', { lineHeight: '1rem' }],
      'sm': ['0.875rem', { lineHeight: '1.25rem' }],
      'base': ['1rem', { lineHeight: '1.5rem' }],
      'lg': ['1.125rem', { lineHeight: '1.75rem' }],
      'xl': ['1.25rem', { lineHeight: '1.75rem' }],
      '2xl': ['1.5rem', { lineHeight: '2rem' }],
      '3xl': ['1.875rem', { lineHeight: '2.25rem' }],
      '4xl': ['2.25rem', { lineHeight: '2.5rem' }],
      '5xl': ['3rem', { lineHeight: '1' }],
      '6xl': ['3.75rem', { lineHeight: '1' }],
      '7xl': ['4.5rem', { lineHeight: '1' }],
      '8xl': ['6rem', { lineHeight: '1' }],
      '9xl': ['8rem', { lineHeight: '1' }],
    },
    fontWeight: {
      thin: '100',
      extralight: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    gap: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
    },
    gradientColorStops: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    gradientColorStopPositions: {
      '0%': '0%',
      '5%': '5%',
      '10%': '10%',
      '15%': '15%',
      '20%': '20%',
      '25%': '25%',
      '30%': '30%',
      '35%': '35%',
      '40%': '40%',
      '45%': '45%',
      '50%': '50%',
      '55%': '55%',
      '60%': '60%',
      '65%': '65%',
      '70%': '70%',
      '75%': '75%',
      '80%': '80%',
      '85%': '85%',
      '90%': '90%',
      '95%': '95%',
      '100%': '100%',
    },
    grayscale: { '0': '0', 'DEFAULT': '100%' },
    gridAutoColumns: {
      auto: 'auto',
      min: 'min-content',
      max: 'max-content',
      fr: 'minmax(0, 1fr)',
    },
    gridAutoRows: {
      auto: 'auto',
      min: 'min-content',
      max: 'max-content',
      fr: 'minmax(0, 1fr)',
    },
    gridColumn: {
      'auto': 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
      'span-7': 'span 7 / span 7',
      'span-8': 'span 8 / span 8',
      'span-9': 'span 9 / span 9',
      'span-10': 'span 10 / span 10',
      'span-11': 'span 11 / span 11',
      'span-12': 'span 12 / span 12',
      'span-full': '1 / -1',
    },
    gridColumnEnd: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
      'auto': 'auto',
    },
    gridColumnStart: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
      'auto': 'auto',
    },
    gridRow: {
      'auto': 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
      'span-7': 'span 7 / span 7',
      'span-8': 'span 8 / span 8',
      'span-9': 'span 9 / span 9',
      'span-10': 'span 10 / span 10',
      'span-11': 'span 11 / span 11',
      'span-12': 'span 12 / span 12',
      'span-full': '1 / -1',
    },
    gridRowEnd: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
      'auto': 'auto',
    },
    gridRowStart: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
      'auto': 'auto',
    },
    gridTemplateColumns: {
      '1': 'repeat(1, minmax(0, 1fr))',
      '2': 'repeat(2, minmax(0, 1fr))',
      '3': 'repeat(3, minmax(0, 1fr))',
      '4': 'repeat(4, minmax(0, 1fr))',
      '5': 'repeat(5, minmax(0, 1fr))',
      '6': 'repeat(6, minmax(0, 1fr))',
      '7': 'repeat(7, minmax(0, 1fr))',
      '8': 'repeat(8, minmax(0, 1fr))',
      '9': 'repeat(9, minmax(0, 1fr))',
      '10': 'repeat(10, minmax(0, 1fr))',
      '11': 'repeat(11, minmax(0, 1fr))',
      '12': 'repeat(12, minmax(0, 1fr))',
      'none': 'none',
      'subgrid': 'subgrid',
    },
    gridTemplateRows: {
      '1': 'repeat(1, minmax(0, 1fr))',
      '2': 'repeat(2, minmax(0, 1fr))',
      '3': 'repeat(3, minmax(0, 1fr))',
      '4': 'repeat(4, minmax(0, 1fr))',
      '5': 'repeat(5, minmax(0, 1fr))',
      '6': 'repeat(6, minmax(0, 1fr))',
      '7': 'repeat(7, minmax(0, 1fr))',
      '8': 'repeat(8, minmax(0, 1fr))',
      '9': 'repeat(9, minmax(0, 1fr))',
      '10': 'repeat(10, minmax(0, 1fr))',
      '11': 'repeat(11, minmax(0, 1fr))',
      '12': 'repeat(12, minmax(0, 1fr))',
      'none': 'none',
      'subgrid': 'subgrid',
    },
    height: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'auto': 'auto',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      'full': '100%',
      'screen': '100vh',
      'svh': '100svh',
      'lvh': '100lvh',
      'dvh': '100dvh',
      'min': 'min-content',
      'max': 'max-content',
      'fit': 'fit-content',
    },
    hueRotate: {
      '0': '0deg',
      '15': '15deg',
      '30': '30deg',
      '60': '60deg',
      '90': '90deg',
      '180': '180deg',
    },
    inset: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'auto': 'auto',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      'full': '100%',
    },
    invert: { '0': '0', 'DEFAULT': '100%' },
    keyframes: {
      'spin': { to: { transform: 'rotate(360deg)' } },
      'ping': { '75%, 100%': { transform: 'scale(2)', opacity: '0' } },
      'pulse': { '50%': { opacity: '.5' } },
      'bounce': {
        '0%, 100%': {
          transform: 'translateY(-25%)',
          animationTimingFunction: 'cubic-bezier(0.8,0,1,1)',
        },
        '50%': {
          transform: 'none',
          animationTimingFunction: 'cubic-bezier(0,0,0.2,1)',
        },
      },
      'enter': {
        from: {
          opacity: 'var(--tw-enter-opacity, 1)',
          transform:
            'translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0))',
        },
      },
      'exit': {
        to: {
          opacity: 'var(--tw-exit-opacity, 1)',
          transform:
            'translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0))',
        },
      },
      'accordion-down': {
        from: { height: '0' },
        to: { height: 'var(--radix-accordion-content-height)' },
      },
      'accordion-up': {
        from: { height: 'var(--radix-accordion-content-height)' },
        to: { height: '0' },
      },
      'blink': {
        '0%': { opacity: '1' },
        '50%': { opacity: '0' },
        '100%': { opacity: '1' },
      },
      'circular-progress-rotate': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
      },
      'circular-progress-dash': {
        '0%': { 'stroke-dasharray': '1px, 200px', 'stroke-dashoffset': '0' },
        '50%': {
          'stroke-dasharray': '100px, 200px',
          'stroke-dashoffset': '-15px',
        },
        '100%': {
          'stroke-dasharray': '100px, 200px',
          'stroke-dashoffset': '-125px',
        },
      },
      'linear-progress-indeterminate-bar1': {
        '0%': { left: '-35%', right: '100%' },
        '60%': { left: '100%', right: '-90%' },
        '100%': { left: '100%', right: '-90%' },
      },
      'linear-progress-indeterminate-bar2': {
        '0%': { left: '-200%', right: '100%' },
        '60%': { left: '107%', right: '-8%' },
        '100%': { left: '107%', right: '-8%' },
      },
      'wave': {
        '0%': { transform: 'translateX(-100%)' },
        '50%': { transform: 'translateX(100%)' },
        '100%': { transform: 'translateX(100%)' },
      },
    },
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0em',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
    },
    lineHeight: {
      '3': '.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      'none': '1',
      'tight': '1.25',
      'snug': '1.375',
      'normal': '1.5',
      'relaxed': '1.625',
      'loose': '2',
    },
    listStyleType: { none: 'none', disc: 'disc', decimal: 'decimal' },
    listStyleImage: { none: 'none' },
    margin: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'auto': 'auto',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
    },
    lineClamp: { '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6' },
    maxHeight: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      'none': 'none',
      'full': '100%',
      'screen': '100vh',
      'svh': '100svh',
      'lvh': '100lvh',
      'dvh': '100dvh',
      'min': 'min-content',
      'max': 'max-content',
      'fit': 'fit-content',
    },
    maxWidth: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      'none': 'none',
      'xs': '20rem',
      'sm': '24rem',
      'md': '28rem',
      'lg': '32rem',
      'xl': '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem',
      '7xl': '80rem',
      'full': '100%',
      'min': 'min-content',
      'max': 'max-content',
      'fit': 'fit-content',
      'prose': '65ch',
      'screen-sm': '640px',
      'screen-md': '768px',
      'screen-lg': '1024px',
      'screen-xl': '1280px',
      'screen-2xl': '1536px',
    },
    minHeight: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      'full': '100%',
      'screen': '100vh',
      'svh': '100svh',
      'lvh': '100lvh',
      'dvh': '100dvh',
      'min': 'min-content',
      'max': 'max-content',
      'fit': 'fit-content',
    },
    minWidth: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      'full': '100%',
      'min': 'min-content',
      'max': 'max-content',
      'fit': 'fit-content',
    },
    objectPosition: {
      'bottom': 'bottom',
      'center': 'center',
      'left': 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      'right': 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      'top': 'top',
    },
    opacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '15': '0.15',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '35': '0.35',
      '40': '0.4',
      '45': '0.45',
      '50': '0.5',
      '55': '0.55',
      '60': '0.6',
      '65': '0.65',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '85': '0.85',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
    },
    order: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      'first': '-9999',
      'last': '9999',
      'none': '0',
    },
    outlineColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    outlineOffset: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
    },
    outlineWidth: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
    },
    padding: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
    },
    placeholderColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    placeholderOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '15': '0.15',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '35': '0.35',
      '40': '0.4',
      '45': '0.45',
      '50': '0.5',
      '55': '0.55',
      '60': '0.6',
      '65': '0.65',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '85': '0.85',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
    },
    ringColor: {
      DEFAULT: '#3b82f6',
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    ringOffsetColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    ringOffsetWidth: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
    },
    ringOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '15': '0.15',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '35': '0.35',
      '40': '0.4',
      '45': '0.45',
      '50': '0.5',
      '55': '0.55',
      '60': '0.6',
      '65': '0.65',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '85': '0.85',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
      'DEFAULT': '0.5',
    },
    ringWidth: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      'DEFAULT': '3px',
    },
    rotate: {
      '0': '0deg',
      '1': '1deg',
      '2': '2deg',
      '3': '3deg',
      '6': '6deg',
      '12': '12deg',
      '45': '45deg',
      '90': '90deg',
      '180': '180deg',
    },
    saturate: { '0': '0', '50': '.5', '100': '1', '150': '1.5', '200': '2' },
    scale: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '90': '.9',
      '95': '.95',
      '100': '1',
      '105': '1.05',
      '110': '1.1',
      '125': '1.25',
      '150': '1.5',
    },
    scrollMargin: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
    },
    scrollPadding: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
    },
    sepia: { '0': '0', 'DEFAULT': '100%' },
    skew: {
      '0': '0deg',
      '1': '1deg',
      '2': '2deg',
      '3': '3deg',
      '6': '6deg',
      '12': '12deg',
    },
    space: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
    },
    spacing: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
    },
    stroke: {
      none: 'none',
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    strokeWidth: { '0': '0', '1': '1', '2': '2' },
    supports: {},
    data: {},
    textColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    textDecorationColor: {
      current: 'currentColor',
      error: { '1': '#4c272e', '2': '#d32f2f', '3': '#e38a8a', '4': '#fae5e5' },
      info: { '1': '#1d3b54', '2': '#0865b4', '3': '#33ccff', '4': '#c1eaff' },
      neutral: {
        '1': '#141416',
        '2': '#23262f',
        '3': '#353945',
        '4': '#6e7481',
        '5': '#b1b5c3',
        '6': '#e6e8ec',
        '7': '#f4f5f6',
        '8': '#fcfcfd',
      },
      success: {
        '1': '#283d32',
        '2': '#388e3c',
        '3': '#81c784',
        '4': '#e7f4e7',
      },
      transparent: 'transparent',
      warning: {
        '1': '#553925',
        '2': '#f57c00',
        '3': '#ffb74d',
        '4': '#fff1df',
      },
    },
    textDecorationThickness: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      'auto': 'auto',
      'from-font': 'from-font',
    },
    textIndent: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
    },
    textOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '15': '0.15',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '35': '0.35',
      '40': '0.4',
      '45': '0.45',
      '50': '0.5',
      '55': '0.55',
      '60': '0.6',
      '65': '0.65',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '85': '0.85',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
    },
    textUnderlineOffset: {
      '0': '0px',
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
      'auto': 'auto',
    },
    transformOrigin: {
      'center': 'center',
      'top': 'top',
      'top-right': 'top right',
      'right': 'right',
      'bottom-right': 'bottom right',
      'bottom': 'bottom',
      'bottom-left': 'bottom left',
      'left': 'left',
      'top-left': 'top left',
    },
    transitionDelay: {
      '0': '0s',
      '75': '75ms',
      '100': '100ms',
      '150': '150ms',
      '200': '200ms',
      '300': '300ms',
      '500': '500ms',
      '700': '700ms',
      '1000': '1000ms',
    },
    transitionDuration: {
      '0': '0s',
      '75': '75ms',
      '100': '100ms',
      '150': '150ms',
      '200': '200ms',
      '300': '300ms',
      '500': '500ms',
      '700': '700ms',
      '1000': '1000ms',
      'DEFAULT': '150ms',
    },
    transitionProperty: {
      none: 'none',
      all: 'all',
      DEFAULT:
        'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
      colors:
        'color, background-color, border-color, text-decoration-color, fill, stroke',
      opacity: 'opacity',
      shadow: 'box-shadow',
      transform: 'transform',
    },
    transitionTimingFunction: {
      'DEFAULT': 'cubic-bezier(0.4, 0, 0.2, 1)',
      'linear': 'linear',
      'in': 'cubic-bezier(0.4, 0, 1, 1)',
      'out': 'cubic-bezier(0, 0, 0.2, 1)',
      'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    translate: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      'full': '100%',
    },
    size: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'auto': 'auto',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      'full': '100%',
      'min': 'min-content',
      'max': 'max-content',
      'fit': 'fit-content',
    },
    width: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'auto': 'auto',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      'full': '100%',
      'screen': '100vw',
      'svw': '100svw',
      'lvw': '100lvw',
      'dvw': '100dvw',
      'min': 'min-content',
      'max': 'max-content',
      'fit': 'fit-content',
    },
    willChange: {
      auto: 'auto',
      scroll: 'scroll-position',
      contents: 'contents',
      transform: 'transform',
    },
    zIndex: {
      '0': '0',
      '1': '1',
      '10': '10',
      '20': '20',
      '30': '30',
      '40': '40',
      '50': '50',
      '100': '100',
      '500': '500',
      'auto': 'auto',
      'high': '2000000000',
      'top': '2147483647',
    },
    animationDelay: {
      '0': '0s',
      '75': '75ms',
      '100': '100ms',
      '150': '150ms',
      '200': '200ms',
      '300': '300ms',
      '500': '500ms',
      '700': '700ms',
      '1000': '1000ms',
    },
    animationDuration: {
      '0': '0s',
      '75': '75ms',
      '100': '100ms',
      '150': '150ms',
      '200': '200ms',
      '300': '300ms',
      '500': '500ms',
      '700': '700ms',
      '1000': '1000ms',
      'DEFAULT': '150ms',
    },
    animationTimingFunction: {
      'DEFAULT': 'cubic-bezier(0.4, 0, 0.2, 1)',
      'linear': 'linear',
      'in': 'cubic-bezier(0.4, 0, 1, 1)',
      'out': 'cubic-bezier(0, 0, 0.2, 1)',
      'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    animationFillMode: {
      none: 'none',
      forwards: 'forwards',
      backwards: 'backwards',
      both: 'both',
    },
    animationDirection: {
      'normal': 'normal',
      'reverse': 'reverse',
      'alternate': 'alternate',
      'alternate-reverse': 'alternate-reverse',
    },
    animationOpacity: {
      '0': '0',
      '5': '0.05',
      '10': '0.1',
      '15': '0.15',
      '20': '0.2',
      '25': '0.25',
      '30': '0.3',
      '35': '0.35',
      '40': '0.4',
      '45': '0.45',
      '50': '0.5',
      '55': '0.55',
      '60': '0.6',
      '65': '0.65',
      '70': '0.7',
      '75': '0.75',
      '80': '0.8',
      '85': '0.85',
      '90': '0.9',
      '95': '0.95',
      '100': '1',
      'DEFAULT': 0,
    },
    animationTranslate: {
      '0': '0px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '11': '2.75rem',
      '12': '3rem',
      '14': '3.5rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '28': '7rem',
      '32': '8rem',
      '36': '9rem',
      '40': '10rem',
      '44': '11rem',
      '48': '12rem',
      '52': '13rem',
      '56': '14rem',
      '60': '15rem',
      '64': '16rem',
      '72': '18rem',
      '80': '20rem',
      '96': '24rem',
      'DEFAULT': '100%',
      'px': '1px',
      '0.5': '0.125rem',
      '1.5': '0.375rem',
      '2.5': '0.625rem',
      '3.5': '0.875rem',
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      'full': '100%',
    },
    animationScale: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '90': '.9',
      '95': '.95',
      '100': '1',
      '105': '1.05',
      '110': '1.1',
      '125': '1.25',
      '150': '1.5',
      'DEFAULT': 0,
    },
    animationRotate: {
      '0': '0deg',
      '1': '1deg',
      '2': '2deg',
      '3': '3deg',
      '6': '6deg',
      '12': '12deg',
      '45': '45deg',
      '90': '90deg',
      '180': '180deg',
      'DEFAULT': '30deg',
    },
    animationRepeat: { '0': '0', '1': '1', 'infinite': 'infinite' },
  },
  corePlugins: [
    'preflight',
    'container',
    'accessibility',
    'pointerEvents',
    'visibility',
    'position',
    'inset',
    'isolation',
    'zIndex',
    'order',
    'gridColumn',
    'gridColumnStart',
    'gridColumnEnd',
    'gridRow',
    'gridRowStart',
    'gridRowEnd',
    'float',
    'clear',
    'margin',
    'boxSizing',
    'lineClamp',
    'display',
    'aspectRatio',
    'size',
    'height',
    'maxHeight',
    'minHeight',
    'width',
    'minWidth',
    'maxWidth',
    'flex',
    'flexShrink',
    'flexGrow',
    'flexBasis',
    'tableLayout',
    'captionSide',
    'borderCollapse',
    'borderSpacing',
    'transformOrigin',
    'translate',
    'rotate',
    'skew',
    'scale',
    'transform',
    'animation',
    'cursor',
    'touchAction',
    'userSelect',
    'resize',
    'scrollSnapType',
    'scrollSnapAlign',
    'scrollSnapStop',
    'scrollMargin',
    'scrollPadding',
    'listStylePosition',
    'listStyleType',
    'listStyleImage',
    'appearance',
    'columns',
    'breakBefore',
    'breakInside',
    'breakAfter',
    'gridAutoColumns',
    'gridAutoFlow',
    'gridAutoRows',
    'gridTemplateColumns',
    'gridTemplateRows',
    'flexDirection',
    'flexWrap',
    'placeContent',
    'placeItems',
    'alignContent',
    'alignItems',
    'justifyContent',
    'justifyItems',
    'gap',
    'space',
    'divideWidth',
    'divideStyle',
    'divideColor',
    'divideOpacity',
    'placeSelf',
    'alignSelf',
    'justifySelf',
    'overflow',
    'overscrollBehavior',
    'scrollBehavior',
    'textOverflow',
    'hyphens',
    'whitespace',
    'textWrap',
    'wordBreak',
    'borderRadius',
    'borderWidth',
    'borderStyle',
    'borderColor',
    'borderOpacity',
    'backgroundColor',
    'backgroundOpacity',
    'backgroundImage',
    'gradientColorStops',
    'boxDecorationBreak',
    'backgroundSize',
    'backgroundAttachment',
    'backgroundClip',
    'backgroundPosition',
    'backgroundRepeat',
    'backgroundOrigin',
    'fill',
    'stroke',
    'strokeWidth',
    'objectFit',
    'objectPosition',
    'padding',
    'textAlign',
    'textIndent',
    'verticalAlign',
    'fontFamily',
    'fontSize',
    'fontWeight',
    'textTransform',
    'fontStyle',
    'fontVariantNumeric',
    'lineHeight',
    'letterSpacing',
    'textColor',
    'textOpacity',
    'textDecoration',
    'textDecorationColor',
    'textDecorationStyle',
    'textDecorationThickness',
    'textUnderlineOffset',
    'fontSmoothing',
    'placeholderColor',
    'placeholderOpacity',
    'caretColor',
    'accentColor',
    'opacity',
    'backgroundBlendMode',
    'mixBlendMode',
    'boxShadow',
    'boxShadowColor',
    'outlineStyle',
    'outlineWidth',
    'outlineOffset',
    'outlineColor',
    'ringWidth',
    'ringColor',
    'ringOpacity',
    'ringOffsetWidth',
    'ringOffsetColor',
    'blur',
    'brightness',
    'contrast',
    'dropShadow',
    'grayscale',
    'hueRotate',
    'invert',
    'saturate',
    'sepia',
    'filter',
    'backdropBlur',
    'backdropBrightness',
    'backdropContrast',
    'backdropGrayscale',
    'backdropHueRotate',
    'backdropInvert',
    'backdropOpacity',
    'backdropSaturate',
    'backdropSepia',
    'backdropFilter',
    'transitionProperty',
    'transitionDelay',
    'transitionDuration',
    'transitionTimingFunction',
    'willChange',
    'contain',
    'content',
    'forcedColorAdjust',
  ],
  plugins: [
    {
      config: {
        theme: {
          extend: {
            animationFillMode: {
              none: 'none',
              forwards: 'forwards',
              backwards: 'backwards',
              both: 'both',
            },
            animationDirection: {
              'normal': 'normal',
              'reverse': 'reverse',
              'alternate': 'alternate',
              'alternate-reverse': 'alternate-reverse',
            },
            animationRepeat: { '0': '0', '1': '1', 'infinite': 'infinite' },
            keyframes: {
              enter: {
                from: {
                  opacity: 'var(--tw-enter-opacity, 1)',
                  transform:
                    'translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0))',
                },
              },
              exit: {
                to: {
                  opacity: 'var(--tw-exit-opacity, 1)',
                  transform:
                    'translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0))',
                },
              },
            },
          },
        },
      },
    },
    {
      config: {
        theme: {
          containers: {
            'xs': '20rem',
            'sm': '24rem',
            'md': '28rem',
            'lg': '32rem',
            'xl': '36rem',
            '2xl': '42rem',
            '3xl': '48rem',
            '4xl': '56rem',
            '5xl': '64rem',
            '6xl': '72rem',
            '7xl': '80rem',
          },
        },
      },
    },
    { config: { theme: { extend: { colors: {} } } } },
    {},
  ],
  content: {
    relative: false,
    files: [
      './node_modules/@motortrend/ids/**/*.{js,jsx}',
      './app/**/*.{js,ts,jsx,tsx}',
      './components/**/*.{js,ts,jsx,tsx}',
      './lib/**/*.{js,ts,jsx,tsx}',
      './packages/**/*.{js,ts,jsx,tsx}',
      './modules/**/*.{js,ts,jsx,tsx}',
      './page/**/*.{js,ts,jsx,tsx}',
    ],
    extract: {},
    transform: {},
  },
  presets: [
    {
      content: [],
      darkMode: 'selector',
      plugins: [
        {
          config: {
            theme: {
              extend: {
                animationFillMode: {
                  none: 'none',
                  forwards: 'forwards',
                  backwards: 'backwards',
                  both: 'both',
                },
                animationDirection: {
                  'normal': 'normal',
                  'reverse': 'reverse',
                  'alternate': 'alternate',
                  'alternate-reverse': 'alternate-reverse',
                },
                animationRepeat: { '0': '0', '1': '1', 'infinite': 'infinite' },
                keyframes: {
                  enter: {
                    from: {
                      opacity: 'var(--tw-enter-opacity, 1)',
                      transform:
                        'translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0))',
                    },
                  },
                  exit: {
                    to: {
                      opacity: 'var(--tw-exit-opacity, 1)',
                      transform:
                        'translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0))',
                    },
                  },
                },
              },
            },
          },
        },
        {
          config: {
            theme: {
              containers: {
                'xs': '20rem',
                'sm': '24rem',
                'md': '28rem',
                'lg': '32rem',
                'xl': '36rem',
                '2xl': '42rem',
                '3xl': '48rem',
                '4xl': '56rem',
                '5xl': '64rem',
                '6xl': '72rem',
                '7xl': '80rem',
              },
            },
          },
        },
        { config: { theme: { extend: { colors: {} } } } },
        {},
      ],
      safelist: [
        {
          pattern: {},
          variants: [
            'sm',
            'md',
            'lg',
            'xl',
            '2xl',
            '@xs',
            '@sm',
            '@md',
            '@lg',
            '@xl',
          ],
        },
      ],
      theme: {
        colors: {
          current: 'currentColor',
          error: {
            '1': '#4c272e',
            '2': '#d32f2f',
            '3': '#e38a8a',
            '4': '#fae5e5',
          },
          info: {
            '1': '#1d3b54',
            '2': '#0865b4',
            '3': '#33ccff',
            '4': '#c1eaff',
          },
          neutral: {
            '1': '#141416',
            '2': '#23262f',
            '3': '#353945',
            '4': '#6e7481',
            '5': '#b1b5c3',
            '6': '#e6e8ec',
            '7': '#f4f5f6',
            '8': '#fcfcfd',
          },
          success: {
            '1': '#283d32',
            '2': '#388e3c',
            '3': '#81c784',
            '4': '#e7f4e7',
          },
          transparent: 'transparent',
          warning: {
            '1': '#553925',
            '2': '#f57c00',
            '3': '#ffb74d',
            '4': '#fff1df',
          },
        },
        containers: {
          'xs': '320px',
          'sm': '384px',
          'md': '448px',
          'lg': '512px',
          'xl': '576px',
          '2xl': '672px',
          '3xl': '768px',
          '4xl': '896px',
          '5xl': '1024px',
          '6xl': '1152px',
          '7xl': '1280px',
        },
        extend: {
          animation: {
            'accordion-down': 'accordion-down 0.2s ease-out',
            'accordion-up': 'accordion-up 0.2s ease-out',
            'circular-progress':
              'circular-progress-rotate 1.4s linear infinite',
            'circular-progress-dash':
              'circular-progress-dash 1.4s ease-in-out infinite',
            'linear-progress-bar1':
              'linear-progress-indeterminate-bar1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite',
            'linear-progress-bar2':
              'linear-progress-indeterminate-bar2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite',
            'wave': 'wave 2s linear 0.5s infinite',
          },
          aspectRatio: { paper: '8.5 / 11' },
          backgroundImage: {
            'gradient-circle':
              'radial-gradient(circle, var(--tw-gradient-stops))',
          },
          keyframes: {
            'accordion-down': {
              from: { height: '0' },
              to: { height: 'var(--radix-accordion-content-height)' },
            },
            'accordion-up': {
              from: { height: 'var(--radix-accordion-content-height)' },
              to: { height: '0' },
            },
            'blink': {
              '0%': { opacity: '1' },
              '50%': { opacity: '0' },
              '100%': { opacity: '1' },
            },
            'circular-progress-rotate': {
              '0%': { transform: 'rotate(0deg)' },
              '100%': { transform: 'rotate(360deg)' },
            },
            'circular-progress-dash': {
              '0%': {
                'stroke-dasharray': '1px, 200px',
                'stroke-dashoffset': '0',
              },
              '50%': {
                'stroke-dasharray': '100px, 200px',
                'stroke-dashoffset': '-15px',
              },
              '100%': {
                'stroke-dasharray': '100px, 200px',
                'stroke-dashoffset': '-125px',
              },
            },
            'linear-progress-indeterminate-bar1': {
              '0%': { left: '-35%', right: '100%' },
              '60%': { left: '100%', right: '-90%' },
              '100%': { left: '100%', right: '-90%' },
            },
            'linear-progress-indeterminate-bar2': {
              '0%': { left: '-200%', right: '100%' },
              '60%': { left: '107%', right: '-8%' },
              '100%': { left: '107%', right: '-8%' },
            },
            'wave': {
              '0%': { transform: 'translateX(-100%)' },
              '50%': { transform: 'translateX(100%)' },
              '100%': { transform: 'translateX(100%)' },
            },
          },
          zIndex: {
            '1': '1',
            '100': '100',
            '500': '500',
            'high': '2000000000',
            'top': '2147483647',
          },
        },
        fontFamily: {
          email: 'Arial',
          body: 'var(--font-body)',
          heading: 'var(--font-heading)',
        },
        screens: {
          'sm': '640px',
          'md': '768px',
          'lg': '1024px',
          'xl': '1280px',
          '2xl': '1536px',
        },
        typography: {
          hero: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            '@apply text-pretty': {},
            'font-size': '6rem',
            'line-height': '6rem',
          },
          h1: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            '@apply text-pretty': {},
            'font-size': '4rem',
            'line-height': '4rem',
          },
          h2: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            '@apply text-pretty': {},
            'font-size': '3rem',
            'line-height': '3.5rem',
          },
          h3: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            '@apply text-pretty': {},
            'font-size': '2.5rem',
            'line-height': '3rem',
          },
          h4: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            '@apply text-pretty': {},
            'font-size': '2rem',
            'line-height': '2.25rem',
          },
          h5: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            '@apply text-pretty': {},
            'font-size': '1.5rem',
            'line-height': '1.75rem',
          },
          h6: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            '@apply text-pretty': {},
            'font-size': '1.25rem',
            'line-height': '1.75rem',
          },
          subtitle1: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            '@apply text-pretty': {},
            'font-size': '1.125rem',
            'line-height': '1.5rem',
          },
          subtitle2: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            '@apply text-pretty': {},
            'font-size': '1rem',
            'line-height': '1.375rem',
          },
          body1: {
            '@apply font-body': {},
            '@apply text-pretty': {},
            'font-size': '1.25rem',
            'line-height': '2rem',
          },
          body2: {
            '@apply font-body': {},
            '@apply text-pretty': {},
            'font-size': '1.125rem',
            'line-height': '1.75rem',
          },
          body3: {
            '@apply font-body': {},
            '@apply text-pretty': {},
            'font-size': '1rem',
            'line-height': '1.5rem',
          },
          button1: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            'font-size': '1rem',
            'line-height': '1rem',
          },
          button2: {
            '@apply font-bold': {},
            '@apply font-heading': {},
            'font-size': '0.875rem',
            'line-height': '1rem',
          },
          caption1: {
            '@apply font-body': {},
            '@apply text-pretty': {},
            'font-size': '0.875rem',
            'line-height': '1.125rem',
          },
          caption2: {
            '@apply font-body': {},
            '@apply text-pretty': {},
            'font-size': '0.75rem',
            'line-height': '1rem',
          },
        },
      },
    },
  ],
  darkMode: 'selector',
  safelist: [
    {
      pattern: {},
      variants: [
        'sm',
        'md',
        'lg',
        'xl',
        '2xl',
        '@xs',
        '@sm',
        '@md',
        '@lg',
        '@xl',
      ],
    },
  ],
  prefix: '',
  important: false,
  separator: ':',
  blocklist: [],
};
